/* eslint-disable @typescript-eslint/naming-convention */
import Head from 'next/head';

export const AppHead = ({ pageTitle }: { pageTitle: string }) => (
  <Head>
    <title>{pageTitle}</title>
    <meta name="description" content="Filmmakers Mart is a comprehensive solution for all your filming needs - from scouting ideal locations to managing logistics and hiring talent." />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <link rel="icon" href="/favicon.ico" />
  </Head>
);

export interface ITheme {
  colors: {
    red: string;
    primaryText: string;
    secondaryText: string;
    primaryHeader: string;
    secondaryHeader: string;
    tertiaryText: string;
    primaryBg: string;
    secondaryBg: string;
    tertiaryBg: string;
    footer: string;
    borders: string;
    buttonBG: string;
    accent: {
      prices: string;
      success: string;
      pending: string;
      ongoing: string;
      gold: string;
    };
    text: {
      header: string;
      body: string;
      primary: string;
      white: string;
      logoText: string;
      logo: string;
      label: string;
      accent: string;
    };
    icons: {
      primary: string;
      secondary: string;
    };
    border1: string;
    border: string;
    divider: string;
    pryBg: string;
    secBg: string;
    layoutBg: string;
    button: {
      secondary: string;
      primary: string;
    };
    primary: string;
    deepBlue: string;
  };
  padding: {
    mbg: string;
    bg: string;
    lg: string;
    sm: string;
    input: string;
    containerPadding: string;
  };
  notificationPadding: string;
  iconBg: {
    info: string;
    warning: string;
  };
  margin: {
    lg: string;
    sm: string;
  };
  headerSize: {
    xs: string;
    sm: string;
    md: string;
    l: string;
    xl: string;
    xxl: string;
  };
  textSize: {
    xs: string;
    sm: string;
    md: string;
    l: string;
    xl: string;
  };
  textLineHeight: {
    xs: string;
    sm: string;
    md: string;
    l: string;
    xl: string;
  };
  HeaderLineHeight: {
    xs: string;
    sm: string;
    md: string;
    l: string;
    xl: string;
    xxl: string;
  };
  borderRadius: {
    button: string;
    info: string;
  };
}


export const FmmTheme: ITheme = {
  colors: {
    red: '#FF6029',
    primaryText: '#8a9486',
    secondaryText: '#8A94A6',
    primaryHeader: '#040E23',
    secondaryHeader: '#fff',
    tertiaryText: '#4e5d78',
    primaryBg: '#FAFBFC',
    secondaryBg: '#040E23',
    tertiaryBg: '#f3f3f3',
    footer: '#040e23',
    borders: '#5D5FEF',
    buttonBG: '#4E5D78',
    accent: {
      prices: '#3538CD',
      success: '#38cbb9',
      pending: '#ffab00',
      ongoing: '#5d5fef',
      gold: '#7c5e10',
    },
    text: {
      header: '#1d2939',
      body: '#667085',
      primary: '#FF6029',
      white: '#fff',
      logoText: '#101828',
      logo: '#03053D',
      label: '#1D2939',
      accent: '#3538CD',
    },
    icons: {
      primary: '#FF6029',
      secondary: '#9882b3',
    },
    border1: '#d0d5dd',
    border: '#5d5fef',
    divider: '#eaecf0',
    pryBg: '#fcfcfd',
    secBg: '#f9fafb',
    layoutBg: '#040E23',

    button: {
      secondary: '#eaecf0',
      primary: '#FF6029',
    },
    primary: '#FF6029',
    deepBlue: '#1D2939',

  },
  padding: {
    mbg: '100px 6% 60px 6%',
    bg: '130px 6% 60px 6%',
    lg: '60px 6%',
    sm: '60px 15px',
    input: '10px 14px',
    containerPadding: '20px 6% 0 6%',
  },

  notificationPadding: '10px 16px',
  iconBg: {
    info: '#3538cd14',
    warning: '#FEF0C7',
  },

  margin: {
    lg: '4rem',
    sm: '2rem',
  },

  headerSize: {
    xs: '1.5rem',
    sm: '1.875rem',
    md: '2.25rem',
    l: '3rem',
    xl: '3.75rem',
    xxl: '4.5rem',
  },

  textSize: {
    xs: '0.75rem',
    sm: '0.875rem',
    md: '1rem',
    l: '1.125rem',
    xl: '1.25rem',
  },

  textLineHeight: {
    xs: '1.125rem',
    sm: '1.25rem',
    md: '1.5rem',
    l: '1.75rem',
    xl: '1.875rem',
  },

  HeaderLineHeight: {
    xs: '2rem',
    sm: '2.375rem',
    md: '2.75rem',
    l: '3.75rem',
    xl: '4.5rem',
    xxl: '5.625rem',
  },

  borderRadius: {
    button: '44px',
    info: '8px',
  },

};

export const FMMTheme = {
  colors: {
    grey_50: '#F2F2F2',
    grey_100: '#F8F8F8',
    grey_200: '#EAECF0',
    grey_300: '#D0D5DD',
    grey_400: '#98A2B3',
    grey_500: '#808080',
    grey_600: '#747474',
    grey_700: '#5B5B5B',
    grey_800: '#464646',
    grey_900: '#363636',
    primary_50: '#FFEFEA',
    primary_100: '#ffcebd',
    primary_200: '#FFB69D',
    primary_500: '#FF6029',
    secondary_500: '#040E23',
    teal_50: '#E6F2F2',
    teal_100: '#B0D8D8',
    teal_500: '#008080',
    white: '#FFF',
    violet_50: '#ECE9F7',
    violet_200: '#A999D8',
    violet_500: '#4422AA',

    // background: {
    //   grey_100: '#F8F8F8',
    // },

    border: {
      grey_200: '#C5C5C5',
    },

    // text: {
    //   grey_900: '#363636',
    // },
  },
  fontSize: {
    xxs: '0.5rem',
    xs: '0.75rem',
    sm: '0.875rem',
    base: '1rem',
    lg: '1.125rem',
    xl: '1.25rem',
    '2xl': '1.5rem',
    '3xl': '1.875rem',
    '4xl': '2.25rem',
    '5xl': '3rem',
    '6xl': '4rem',
  },
  fontWeight: {
    thin: 100,
    extralight: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900,
  },
  padding: {
    none: 0,
    xs: '0.25rem',
    sm: '0.5rem',
    base: '1rem',
    lg: '2rem',
    xl: '4rem',
    '2xl': '8rem',
    '3xl': '12rem',
    '4xl': '16rem',
    '5xl': '20rem',
    '6xl': '24rem',
  },
  margin: {
    none: 0,
    xs: '0.25rem',
    sm: '0.5rem',
    base: '1rem',
    lg: '2rem',
    xl: '4rem',
    '2xl': '8rem',
    '3xl': '12rem',
    '4xl': '16rem',
    '5xl': '20rem',
    '6xl': '24rem',
  },
};

